import React from 'react';

const ContactInformation: React.FC = () => {
  return (
    <div
      className="container d-flex flex-column justify-content-center flex-md-row align-items-center text-white p-2 rounded-4 mt-5 mb-5"
      style={{
        backgroundColor: '#095F60',
        width: '95%', // Full width on mobile
        maxWidth: '650px', // Constrained width on larger screens
      }}
    >
      <h6 className="mb-2 mb-md-0 me-md-3 text-center text-md-start fw-bold">
        Contact Information
      </h6>
      <div
        className="border-end border border-thick-8 d-none d-md-block me-md-3"
        style={{ height: '25px' }}
      ></div>
      <div className="text-center text-md-start">
        <p className="mb-1 fw-bold">For any inquiries, please contact:</p>
        
        
        
        <div className="mb-2" style={{ wordBreak: 'break-word' }}>
          <span className="d-inline">• </span>
          <strong>Support:</strong>
          <br className="d-md-none" />
          <a
            href="mailto:info@sciencephots.com "
            className="text-white text-decoration-none ms-md-1"
          >
            info@sciencephotons.com 
          </a>
        </div>
        <div className="mb-2" style={{ wordBreak: 'break-word' }}>
          <span className="d-inline">• </span> 
          <strong>Editorial Office:</strong>
          <br className="d-md-none" /> 
          <a
            href="mailto:eb.sciencephotons@gmail.com"
            className="text-white text-decoration-none ms-md-1"
          >
            eb.sciencephotons@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactInformation;