import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FaEnvelope } from "react-icons/fa";
import { toast } from "react-toastify";
import { AdminService } from "../../app/service/admin.service";
import SubmissionPage from "../submissionPage/SubmissionPage";
import contactUs from "../../assets/images/contactus-bg.png";

const Contactus = () => {
  const [formData, setFormData] = useState<any>({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors: any = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid";
    if (!formData.subject) newErrors.subject = "Subject is required";
    return newErrors;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      const res = await AdminService.createContactUs(formData);
      if (res.status === "Fail") {
        toast.error(res.error);
      } else {
        toast.success(res.message);
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      }
    } catch (error) {
      toast.error("An error occurred while submitting the manuscript");
      console.error("Error submitting manuscript:", error);
    }
  };

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center text-center text-warning"
        style={{
          backgroundImage: `url(${contactUs})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "200px",
          width: "100%",
        }}
      >
        <h3 className="fw-bold text-white">Contact Us</h3>
      </div>
      <Container className="mt-4 mb-4 p-4">
        <Row className="justify-content-center mb-5">
          <Col
            md={5}
            xs={12}
            className="d-flex flex-column align-items-center justify-content-center text-center mb-4"
            style={{ paddingBottom: "1rem" }}
          >
            <h3 className="pt-3 pb-2 side-font">Get In Touch</h3>
            <p className="text-center mb-2">Science Photons LLC</p>
            <p className="text-center mb-2">8 The Green Suite A Dover</p>
            <p className="text-center mb-2">Delaware 19901</p>
            <p className="text-center mb-2">United States</p>
            <p className="text-center mb-2">info@sciencephotons.com</p>
            <div
              className="contact-icon-box d-flex justify-content-center align-items-center "
              style={{
                backgroundColor: "#f8f9fa",
                width: "80px",
                height: "80px",
                borderRadius: "10px",
                marginTop: "10px",
              }}
            >
              <FaEnvelope style={{ color: "#ffcc00", fontSize: "2rem" }} />
            </div>
          </Col>

          {/* Right side contact form */}
          <Col md={7} xs={12}>
            <div
              className="contact-form-box p-4"
              style={{
                backgroundColor: "#fafafa",
                borderRadius: "10px",
                border: "1px solid #e0e0e0",
              }}
            >
              <h3 className="pt-3 pb-3 side-font">Contact Us</h3>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} xs={12} className="mb-3">
                    <Form.Group controlId="name">
                      <Form.Control
                        type="text"
                        placeholder="Your Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12} className="mb-3">
                    <Form.Group controlId="email">
                      <Form.Control
                        type="email"
                        placeholder="Your E-Mail"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group controlId="subject">
                      <Form.Control
                        type="text"
                        placeholder="Subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        isInvalid={!!errors.subject}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.subject}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group controlId="message">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      className="px-5"
                      style={{
                        backgroundColor: "#00796b",
                        borderColor: "#00796b",
                      }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <SubmissionPage />
    </>
  );
};

export default Contactus;
