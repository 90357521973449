import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container, ButtonGroup } from "react-bootstrap";
import { AdminService } from "../../app/service/admin.service";
import { toast } from "react-toastify";
import ThreeCirclesLoader from "../loader/index";

const HomeCard = () => {
  const navigate = useNavigate();
  const [journals, setJournals] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    getAllJournalsData();
  }, []);

  const getAllJournalsData = () => {
    setLoading(true); // Start loading before data fetch
    const data = {
      search: "",
      start: 1,
      count: 10,
    };

    AdminService.getAllJournalsData(data)
      .then((res) => {
        if (res.status === "Success" && res.data) {
          setJournals(res.data);
        } else {
          toast.error(res.message);
        }
      })
      .catch(() => {
        toast.error("Failed to load journals.");
      })
      .finally(() => {
        setLoading(false); // Stop loading after data fetch
      });
  };

  const handleViewAllJournals = () => {
    navigate("/viewAllJournals");
  };

  const handleCardClick = (journal: any, name: any) => {
    if (name === "jHome") {
      const formattedTitle = journal.journalTitle
        .replace(/\s+/g, "-")
        .toLowerCase();
      navigate(`/journal-details/${formattedTitle}`, { state: { journal } });
    } else if (name === "edHome") {
      const formattedTitle = journal.journalTitle
        .replace(/\s+/g, "-")
        .toLowerCase();
      navigate(`/editor-journal-details/${formattedTitle}`, {
        state: { journal },
      });
    }
  };

  return (
    <Container className="pt-4 pb-4">
      <Row>
        <Col className="d-flex justify-content-between align-items-center mb-4">
          <h4>
            <b>Journals</b>
          </h4>
          <Button
            className="button-color px-3 py-3"
            onClick={handleViewAllJournals}
          >
            View All Journals
          </Button>
        </Col>
      </Row>

      {loading ? ( // Display loader while data is being fetched
        <ThreeCirclesLoader />
      ) : (
        <Row className="pb-4">
          {journals.slice(0, 6).map((journal: any) => (
            <Col
              md={6}
              key={journal._id}
              className="mb-4"
              style={{ cursor: "pointer" }}
            >
              <Card
                className="shadow h-100"
                style={{ backgroundColor: "#FFFDF7" }}
              >
                <Row noGutters className="align-items-stretch h-100">
                  {/* Image on the Left */}
                  <Col xs={12} md={4} className="d-flex">
                    <Card.Img
                      src={journal.journalImage}
                      className="w-100"
                      style={{
                        objectFit: "cover",
                        height: "281",
                        width: "212",
                        borderBottomRightRadius: "0px",
                        borderTopRightRadius: "0px",
                      }}
                    />
                  </Col>

                  {/* Content on the Right */}
                  <Col xs={12} md={8}>
                    <Card.Body className="p-3 d-flex flex-column justify-content-between h-100">
                      {/* Read Time in the Top Right */}
                      <div className="d-flex justify-content-end mb-2">
                        <p
                          className="text-muted"
                          style={{
                            fontSize: "0.8rem",
                            margin: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          3 min read
                        </p>
                      </div>

                      {/* Title */}
                      <Card.Title
                        className="mb-2"
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          paddingRight: "20px",
                        }}
                      >
                        {journal.journalTitle}
                      </Card.Title>

                      {/* ISSN Badge */}
                      <div
                        style={{
                          backgroundColor: "#FFD700",
                          color: "#ffffff",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          fontSize: "0.9rem",
                          fontWeight: "bold",
                          width: "fit-content",
                          marginBottom: "10px",
                        }}
                      >
                        ISSN: {journal.ISSNNumber || "NA"}
                      </div>

                      {/* Description */}
                      <Card.Text
                        style={{
                          fontSize: "0.9rem",
                          color: "#555",
                          marginBottom: "15px",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              journal.description.substring(0, 100) + "...",
                          }}
                        />
                      </Card.Text>

                      <ButtonGroup
                        className="w-100"
                        aria-label="Journal Links"
                        style={{
                          border: "1px solid #095F60",
                          borderRadius: "8px",
                          overflow: "hidden",
                        }}
                      >
                        <Button
                          variant="outline-none"
                          className="custom-button"
                          size="sm"
                          style={{
                            borderRight: "1px solid #095F60",
                            flex: 1,
                            padding: "6px 8px",
                            borderColor: "#095F60", // Set border color
                          }}
                          onClick={() => handleCardClick(journal, "jHome")}
                        >
                          Journal Home
                        </Button>
                        <Button
                          variant="outline-none"
                          size="sm"
                          className="custom-button"
                          style={{
                            borderRight: "1px solid #095F60",
                            flex: 1,
                            padding: "6px 8px",
                            // color: "#095F60", // Set text color
                            borderColor: "#095F60", // Set border color
                          }}
                          onClick={() => handleCardClick(journal, "edHome")}
                        >
                          Editorial Board
                        </Button>
                      </ButtonGroup>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default HomeCard;
