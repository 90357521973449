import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row, Toast } from "react-bootstrap";
import Select from "react-select"; // Import react-select
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import { AdminService } from "../../../app/service/admin.service";
import { UserService } from "../../../app/service/user.service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { countryList } from "../../../config";

const UserCreateEditorialBoard = () => {
  const [formData, setFormData] = useState<any>({
    editorName: "",
    editorAffiliation: "",
    editorDepartment: "",
    editorUniversityName: "",
    editorCountryName: "",
    editorType: "",
    editorBiography: "",
    editorResearchInterest: "",
    editorJournalName: "",
    editorContactNubmer: "",
    editorEmail: "",
    editorUrl:'',
    editorImage: null, // Initialize editorImage in formData
  });
  const loginData: any = sessionStorage.getItem("loginData");
  const userData = JSON.parse(loginData);

  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [journalOptions, setJournalOptions] = useState<any[]>([]);

  useEffect(() => {
    getJournalNames(); // Fetch journal names on component mount
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  // Handle change for react-select
  const handleSelectChange = (selectedOption: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      editorJournalName: selectedOption ? selectedOption.id : "", // Update state with selected value
    }));
  };

  const handleCountryChange = (selectedOption: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      editorCountryName: selectedOption ? selectedOption.value : "", // Update the country name based on selected value
    }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFormData((prevData: any) => ({
        ...prevData,
        editorImage: file, // Store the selected image file
      }));
    }
  };

  const validate = () => {
    const newErrors: any = {};
    if (!formData.editorName) newErrors.editorName = "Editor Name is required";
    if (!formData.editorEmail)
      newErrors.editorEmail = "Editor Email is required";
    if (!formData.editorContactNubmer)
      newErrors.editorContactNubmer = "Editor Contact Number is required";
    if (!formData.editorAffiliation)
      newErrors.editorAffiliation = "Editor Affiliation is required";
    if (!formData.editorDepartment)
      newErrors.editorDepartment = "Editor Department is required";
    if (!formData.editorUniversityName)
      newErrors.editorUniversityName = "Editor University Name is required";
    if (!formData.editorCountryName)
      newErrors.editorCountryName = "Editor Country Name is required";
    if (!formData.editorType) newErrors.editorType = "Editor Type is required";
    if (!formData.editorImage)
      newErrors.editorImage = "Editor Image is required"; // Add validation for editorImage
    // if (!formData.editorJournalName) newErrors.editorJournalName = 'Editor Journal Name is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const loginData: any = sessionStorage.getItem("loginData");
    const data = JSON.parse(loginData);
    if (data?.role?.roleName === "user") {
      formData.editorJournalName = data?.journalName?._id;
    }

    if (validate()) {
      try {
        const formDataToSend = new FormData();
        for (const key in formData) {
          formDataToSend.append(key, formData[key]);
        }

        const response = await AdminService.createEditor(formDataToSend); // Use FormData to send
        toast.success(response.message);
        navigate("/user/editorialList");
      } catch (error) {
        setToastMessage("Error creating Editor Board");
        setToastType("error");
        setShowToast(true);
      }
    }
  };

  const getJournalNames = async () => {
    try {
      const response = await AdminService.allJournalNames("");
      const options = response.data.map((type: any) => ({
        value: type.journalName,
        label: type.journalName,
        id: type._id, // Assuming '_id' is the unique identifier for the journal
      }));
      setJournalOptions(options);
    } catch (error) {
      console.error("Error fetching journal names:", error);
    }
  };

  const handleCancel = () => {
    navigate("/user/editorialList");
  };

  const countryOptions = countryList?.map((type) => ({
    value: type.name,
    label: type.name,
  }));


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <h3>Create Editorial Board</h3>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Name *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor Name"
                      name="editorName"
                      value={formData.editorName}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Email *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor Email"
                      name="editorEmail"
                      value={formData.editorEmail}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorEmail}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorEmail}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Contact Number *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor Contact Number"
                      name="editorContactNubmer"
                      value={formData.editorContactNubmer}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorContactNubmer}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorContactNubmer}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Affiliation *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor Affiliation"
                      name="editorAffiliation"
                      value={formData.editorAffiliation}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorAffiliation}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorAffiliation}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Department *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor Department"
                      name="editorDepartment"
                      value={formData.editorDepartment}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorDepartment}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorDepartment}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor University Name *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor University Name"
                      name="editorUniversityName"
                      value={formData.editorUniversityName}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorUniversityName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorUniversityName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="country">
                    <Form.Label>Editor Country Name *</Form.Label>
                    <Select
                      options={countryOptions}
                      name="editorCountryName"
                      placeholder="Select Your Country"
                      onChange={handleCountryChange}
                    />
                    {errors.editorCountryName && (
                      <div className="text-danger">
                        {errors.editorCountryName}
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Type *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor Type"
                      name="editorType"
                      value={formData.editorType}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorType}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorType}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Image *</Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      isInvalid={!!errors.editorImage}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorImage}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Research Interest</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor Research Interest"
                      name="editorResearchInterest"
                      value={formData.editorResearchInterest}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Journal Name</Form.Label>
                      <Form.Control
                      type="text"
                      name="editorJournalName"
                      value={
                        userData?.journalName?.journalName
                      }
                      disabled/>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Url</Form.Label>
                      <Form.Control
                      type="text"
                      name="editorUrl"
                      placeholder="Enter Editor Url"
                      value={
                        formData?.editorUrl
                      }
                      onChange={handleInputChange}
                      />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Biography</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter Editor Biography"
                      name="editorBiography"
                      value={formData.editorBiography}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
                <Button variant="secondary" onClick={handleCancel} className="ms-4">
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default UserCreateEditorialBoard;
