import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'
import CarouselComponent from '../../components/carouselComponents/CarouselComponent'
import ShortAboutus from '../../components/shortAboutus/ShortAboutus'
import Footer from '../../components/footer/Footer'
import HomeCard from '../../components/homeCard/HomeCard'
import HomeArticles from '../../components/articles/HomeArticles'

const HomePage = () => {

  useEffect(()=>{
    scrollToTop()
  },[])

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};
  return (
    <div>
        <Header/>
        <CarouselComponent/>
        <HomeCard/>
        <HomeArticles/>
        
        <Footer/>
    </div>
  )
}

export default HomePage