import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import SubmissionPage from "../submissionPage/SubmissionPage";
import { AdminService } from "../../app/service/admin.service";
import { toast } from "react-toastify";
import { FaLockOpen } from "react-icons/fa";
import { IoMdPerson } from "react-icons/io";
import ThreeCirclesLoader from "../loader/index";

const HomeArticles = () => {
  const navigate = useNavigate();
  const [journals, setJournals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllJournalsData();
  }, []);

  const getAllJournalsData = () => {
    setLoading(true);
    const data = {
      search: "",
      start: 1,
      count: 10,
    };

    AdminService.getAllArticles(data)
      .then((res) => {
        if (res.status === "Success" && res.data) {
          setJournals(res.data);
        } else {
          toast.error(res.message);
        }
      })
      .catch(() => {
        toast.error("Failed to load articles.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAbstract = (item: any) => {
    let abstractId = item?.articlejournalName;
    navigate(`/abstract/${abstractId}`, { state: item });
  };

  return (
    <>
      <div className="pt-4 pb-4 lastestArticles">
        <Container>
          <h2 className="text-left mt-4 mb-4">
            <b>Latest Articles from Science Photons</b>
          </h2>
          {loading ? (
            <ThreeCirclesLoader />
          ) : journals.length > 0 ? (
            <Row className="pb-3 pt-3">
              {journals.slice(0, 9).map((journal: any) => (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  key={journal._id}
                  className="d-flex align-items-stretch mb-4"
                >
                  <Card className="shadow-lg border-0 h-100 w-100 rounded">
                    <div
                      className="article-info d-flex flex-column flex-lg-row align-items-center justify-content-lg-between justify-content-center w-100 p-3"
                      style={{ gap: "0.5rem",backgroundColor:"rgba(251, 247, 234, 1)" }}
                    >
                      <div
                        className="fw-bold article-des text-truncate"
                        style={{
                          color: "black",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          paddingRight: "0.5rem",
                        }}
                      >
                        {journal.articleType}
                      </div>

                      <div
                        className="article-open d-flex justify-content-center align-items-center w-100 w-lg-auto p-lg-0 px-3"
                        style={{
                          color: "#666",
                          fontSize: "0.85rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "155px",
                        }}
                      >
                        <FaLockOpen className="me-1" />
                        <span className="article-open-text text-truncate">
                          Open Access
                        </span>
                      </div>
                    </div>

                    <Card.Body className="d-flex flex-column">
                      <Card.Title
                        className="mb-2"
                        style={{
                          fontSize: "1.25rem",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        {journal.title.length > 100
                          ? `${journal.title.substring(0, 100)}...`
                          : journal.title}
                      </Card.Title>

                      <Card.Text
                        className="text-muted mb-3"
                        style={{ fontSize: "0.95rem", color: "#555" }}
                      >
                        {journal.doiNumber || "NA"}
                      </Card.Text>

                      <div
                        className="d-flex align-items-center mb-3"
                        style={{ fontSize: "0.8rem", color: "#555" }}
                      >
                        <IoMdPerson
                          className="me-2"
                          style={{ fontSize: "22px" }}
                        />
                        {journal.authorNames || "Unknown Author"}
                      </div>

                      <div className="mt-auto">
                        <div className="d-flex flex-column flex-md-row gap-2">
                          <Button
                            className="article-abstract-btn w-100 w-md-auto"
                            style={{
                              backgroundColor: "#0f766e",
                              color: "white",
                              border: "none",
                              fontSize: "0.85rem",
                              padding: "8px 12px",
                            }}
                            onClick={() => handleAbstract(journal)}
                          >
                            Abstract
                          </Button>
                          {journal.photo && (
                            <Button
                              className="button-color article-abstract-btn w-100 w-md-auto"
                              style={{
                                backgroundColor: "#095c4f",
                                color: "white",
                                border: "none",
                                fontSize: "0.85rem",
                                padding: "8px 12px",
                              }}
                              onClick={() =>
                                window.open(journal.photo, "_blank")
                              }
                            >
                              PDF
                            </Button>
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <p>No articles found.</p>
          )}
        </Container>
      </div>
      {/* <Container> */}
        <div className="mt-5 mb-5">
          <SubmissionPage />
        </div>
      {/* </Container> */}
    </>
  );
};

export default HomeArticles;
