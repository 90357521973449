import API from "../utility/axios";
export class AdminService {
    static getAllUserDetails(data: any): Promise<any> {
        return API.get(`/allUsersList`, { params: data });
    }
    static getAllcontactus(data: any): Promise<any> {
        return API.get(`/allcontactus`, { params: data });
    }

    static addUser(data: any): Promise<any> {
        return API.post(`/createUser`, data);
    }

    static updateUser(data: any): Promise<any> {
        return API.post(`/updateUser`, data);
    }

    static createJournalName(data: any): Promise<any> {
        return API.post(`/createJournalName`, data);
    }

    static updatejournalName(data: any): Promise<any> {
        return API.post(`/updatejournalName`, data);
    }
    static allJournalNames(data: any): Promise<any> {
        return API.get(`/allJournalNames`, { params: data });
    }
    static activeUser(data: any): Promise<any> {
        return API.post(`/activeuser`, data);
    }

    static createEditor(data: any): Promise<any> {
        return API.post(`/createEditor`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static updateEditor(data: any): Promise<any> {
        return API.post(`/updateEditor`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static deleteJournalName(data: any): Promise<any> {
        return API.post(`/deleteJournalName`, data);
    }
    static updateMeet(data: any): Promise<any> {
        return API.post(`/updatemeet`, data);
    }

    static deleteUser(data: any): Promise<any> {
        return API.post(`/deleteUser`, data);
    }
    static deleteContactUs(data: any): Promise<any> {
        return API.post(`/deleteContactUs`, data);
    }


    static deleteRegister(data: any): Promise<any> {
        return API.post(`/deleteRegister`, data);
    }

    static deleteArticle(data: any): Promise<any> {
        return API.post(`/deleteArticle`, data);
    }

    static updateArticleIssues(data: any): Promise<any> {
        return API.post(`/updateArticleIssues`, data);
    }

    static deleteEditor(data: any): Promise<any> {
        return API.post(`/deleteEditor`, data);
    }

    static deleteEditorJoining(data: any): Promise<any> {
        return API.post(`/deleteEditorJoining`, data);
    }
    static deleteJournalData(data: any): Promise<any> {
        return API.post(`/deleteJournalData`, data);
    }

    static getEditor(data: any): Promise<any> {
        return API.post(`/getEditor`, data);
    }

    static getArticle(data: any): Promise<any> {
        return API.post(`/getArticle`, data);
    }
    static getArchive(data: any): Promise<any> {
        return API.post(`/getArchive`, data);
    }

    static getJournalData(data: any): Promise<any> {
        return API.post(`/getJournalData`, data);
    }


    static getParamsJournalData(journalTitle: string): Promise<any> {
        // Use string interpolation to append journalTitle as a query parameter
        return API.get(`/getParamsJournalData/${journalTitle}`);
    }




    static deleteManuscript(data: any): Promise<any> {
        return API.post(`/deleteManuscript`, data);
    }
    static getAllManuscripts(data: any): Promise<any> {
        return API.get(`/allManuscripts`);
    }
    static deleteHeaderData(data: any): Promise<any> {
        return API.post(`/deleteHeaderData`, data);
    }

    static allHeaderData(data: any): Promise<any> {
        return API.get(`/allHeaderData`);
    }


    static createArticle(data: any): Promise<any> {
        return API.post(`/createArticle`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static editArticle(data: any): Promise<any> {
        return API.post(`/updateArticle`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static createJournalData(data: any): Promise<any> {
        return API.post(`/createJournalData`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static createHeaderData(data: any): Promise<any> {
        return API.post(`/createHeaderData`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    static updateHeaderData(data: any): Promise<any> {
        return API.post(`/updateHeaderData`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }


    static updateJournalData(data: any): Promise<any> {
        return API.post(`/updateJournalData`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }



    static updateCourse(data: any): Promise<any> {
        return API.post(`/updatecourse`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static getAllEditors(data: any): Promise<any> {
        return API.get(`/allEditors`, { params: data });
    }

    static getAllEditorsJoining(data: any): Promise<any> {
        return API.get(`/allEditorJoining`, { params: data });
    }

    static getAllJournalNames(data: any): Promise<any> {
        return API.get(`/allJournalNames`, { params: data });
    }

    static getAllArticles(data: any): Promise<any> {
        return API.get(`/allArticles`, { params: data });
    }

    static getAllJournalsData(data: any): Promise<any> {
        return API.get(`/allJournalsData`, { params: data });
    }

    static getAllQueriesList(data: any): Promise<any> {
        return API.get(`/allQueriesList`, { params: data });
    }

    static deleteQueries(data: any): Promise<any> {
        return API.post(`/deleteQueries`, data);
    }

    static deleteMeet(data: any): Promise<any> {
        return API.post(`/deleteMeet`, data);
    }

    static getCourseList(data: any): Promise<any> {
        return API.get(`/allcourses`, { params: data });
    }

    static deleteCourse(data: any): Promise<any> {
        return API.post(`/deleteCourse`, data);
    }

    // static createManuscript(data: any): Promise<any> {
    //     return API.post(`/createManuscript`, data);
    // }

    static getallCertificatesList(data: any): Promise<any> {
        return API.get(`/allCertificatesList`, { params: data });
    }

    static getallProjectsList(data: any): Promise<any> {
        return API.get(`/allprojects`, { params: data });
    }

    static createCertificate(data: any): Promise<any> {
        return API.post(`/createCertificate`, data);
    }


    // static createCertificate(data: any): Promise<any> {
    //     console.log("data",data)
    //     return API.post(`/createCertificate`, data, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     });
    // }
    static createProject(data: any): Promise<any> {
        return API.post(`/createProject`, data);
    }

    static getAllCgpaList(data: any): Promise<any> {
        return API.get(`/allCgpaLists`, { params: data });
    }
    static getallCareerGuidancesList(data: any): Promise<any> {
        return API.get(`/allCareerGuidances`, { params: data });
    }

    static createCgpaAccess(data: any): Promise<any> {
        return API.post(`/createCgpaAccess`, data);
    }
    static updateCareerGuidance(data: any): Promise<any> {
        return API.post(`/updateCareerGuidance`, data);
    }
    static createManuscript(data: any): Promise<any> {
        return API.post(`/createManuscript`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static createContactUs(data: any): Promise<any> {
        return API.post(`/createcontactus`, data);
    }

    static getallLmslist(data: any): Promise<any> {
        return API.get(`/allLms`, { params: data });
    }
    static createSignupList(data: any): Promise<any> {
        return API.post(`/createSignUpList`, data);
    }



    static allSignUpList(data: any): Promise<any> {
        return API.get(`/allSignUpList`, { params: data });
    }

    static deleteSignUpList(data: any): Promise<any> {
        return API.post(`/deleteSignupList`, data);
    }
    static getAllSignUpList(data: any): Promise<any> {
        return API.get(`/allSignUpList`);
    }


}